<messages>["../TransferStatus"]</messages>

<template>
  <v-card>
    <v-card-title primary-title>
      <div>
        <div
          v-t="'view.label.detailed'"
          class="text-h5"/>
        <div
          v-t="'view.label.detailedDescription'"
          class="cgwng-subheading"/>
      </div>
    </v-card-title>
    <v-card-text>
      <v-slide-y-transition mode="out-in">
        <v-container
          v-if="isLoading"
          key="loading">
          <v-row>
            <v-progress-linear
              indeterminate
              color="primary"/>
          </v-row>
        </v-container>
        <template v-else>
          <transfer-details
            :id="id"
            key="status"
            :data="data"
            @refresh="refresh"/>
        </template>
      </v-slide-y-transition>
    </v-card-text>
  </v-card>
</template>

<script>
  import TransferDetails from './TransferDetails.vue'

  export default {
    name: 'DetailedStatus',

    components: {
      TransferDetails
    },

    props: {
      data: {
        type: Object,
        default: undefined
      },
      isLoading: Boolean,
      id: {
        type: Number,
        required: true
      }
    },

    computed: {
      items () {
        return Object.keys (this.data)
          .map ((key) => {
            return {
              name: this.$t (`view.label.${key}`),
              value: this.data[key]
            }
          })
      }
    },

    methods: {
      /**
       * emits the refresh event to the parent
       * @param id id of the transfer
       */
      refresh (id) {
        this.$emit ('refresh', id)
      }
    }
  }
</script>
