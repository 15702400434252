var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sortedItems,"items-per-page":_vm.pagination.rowsPerPage,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.descending,"hide-default-footer":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "rowsPerPage", $event)},"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "descending", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:{ highlighted: _vm.highlight === props.index, pointer: true },on:{"click":function($event){_vm.select (props.item.id, props.index)}}},[(_vm.canSeeMemberId && !_vm.$vuetify.breakpoint.smAndDown)?_c('td',[_vm._v(" "+_vm._s(props.item.memberId)+" ")]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formattedDate ( props.item.start, true))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formattedDate (props.item.end, true))+" ")]),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.iconDir(props.item.direction).color}},on),[_vm._v(" "+_vm._s(_vm.iconDir(props.item.direction).icon)+" ")])]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(("view.label.direction." + (props.item.direction))),expression:"`view.label.direction.${props.item.direction}`"}]})])],1),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.iconStatus(props.item.status).color}},on),[_vm._v(" "+_vm._s(_vm.iconStatus(props.item.status).icon)+" ")])]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(("view.label.status." + (props.item.status))),expression:"`view.label.status.${props.item.status}`"}]})])],1),_c('td',{staticClass:"text-center"},[(_vm.hasAvailableDecisions (props.item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.startDecideTransfer (props.item)}}},on),[_c('v-icon',[_vm._v("gavel")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('general.button.decide'),expression:"'general.button.decide'"}]})]):_vm._e(),(_vm.mayCancel (props.item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 pa-0",attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.startCancelTransfer (props.item)}}},on),[_c('v-icon',[_vm._v("close")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('general.button.cancel'),expression:"'general.button.cancel'"}]})]):_vm._e(),(!_vm.hasAvailableDecisions (props.item) && !_vm.mayCancel (props.item))?[_vm._v(" - ")]:_vm._e()],2)])]}}])}),_c('confirmation-dialog',{attrs:{"headline":_vm.$t ('view.cancel.headline')},on:{"ok":_vm.finishCancelTransfer},model:{value:(_vm.showCancelConfirmation),callback:function ($$v) {_vm.showCancelConfirmation=$$v},expression:"showCancelConfirmation"}},[_vm._v(" "+_vm._s(_vm.$t ('view.cancel.text', { object: _vm.transferToCancel ? _vm.transferToCancel.name : null }))+" ")]),_c('decision-dialog',{attrs:{"id":_vm.transferToDecide === null ? 0 : _vm.transferToDecide.id,"options":_vm.transferToDecide === null ? [] : _vm.transferToDecide.options},on:{"decided":_vm.finishDecideTransfer},model:{value:(_vm.showDecisionDialog),callback:function ($$v) {_vm.showDecisionDialog=$$v},expression:"showDecisionDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }