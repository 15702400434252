<messages>["../TransferStatus"]</messages>

<template>
  <v-card
    height="100%"
    width="100%">
    <form @submit.prevent="submit">
      <v-card-title primary-title>
        <div>
          <div
            v-t="'view.label.submit'"
            class="text-h5"/>
          <div
            v-t="'view.label.submitDescription'"
            class="cgwng-subheading"/>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="12" xl="8">
            <radio-group
              v-model="type"
              :option-values="[types.domain, types.email]"
              :option-labels="[$t(`view.label.type.${types.domain}`), $t(`view.label.type.${types.email}`)]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" xl="8">
            <v-text-field
              v-model.trim="name"
              :loading="isLoading"
              :disabled="isLoading"
              :name="type"
              :label="$t(`view.label.type.${type}`)"
              :error-messages="validationErrors(
                'name',
                {
                  required: `required.${type}`,
                })"
              single-line
              @blur="$v.name.$touch()"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.reset'"
          text
          @click="reset()"/>
        <v-btn
          v-t="'general.button.submit'"
          color="primary"
          type="submit"
          :loading="isLoading"/>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
  import {required} from 'vuelidate/lib/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import RadioGroup from '@/app/core/components/RadioGroup'

  import {ObjectType} from './constants'

  export default {
    name: 'SubmitCard',

    components: {
      RadioGroup
    },

    mixins: [validationMixins],

    props: {
      isLoading: Boolean,
      nameValue: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        type: 1,
        name: this.nameValue
      }
    },

    computed: {
      types () {
        return ObjectType
      }
    },

    validations: {
      name: {required}
    },

    watch: {
      nameValue () {
        this.name = this.nameValue
      }
    },

    methods: {
      /**
       * submits the request to the backend
       */
      submit () {
        if (!this.$v.$invalid) {
          this.$emit ('submit', {type: this.type, name: this.name})
        } else {
          this.$v.$touch ()
        }
      },

      /**
       * resets the whole page
       */
      reset () {
        this.name = ''
        this.$emit ('reset')
      }
    }
  }
</script>
