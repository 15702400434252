// transfer status related constants

export const Status = {
  Pending: 1,
  Approved: 2,
  Rejected: 3,
  Cancelled: 4
}

export const ObjectType = {
  email: 4,
  domain: 1
}

export const TypeToName = {
  [ObjectType.domain]: 'domain',
  [ObjectType.email]: 'email'
}

export const DecisionType = {
  Registrant: 1,
  Admin: 2,
  Member: 3
}

export const DetailKeys = {
  Direction: 'direction',
  RegistrantDecision: 'regDecision',
  AdminDecision: 'adminDecision',
  MemberDecision: 'memberDecision',
  Status: 'status',
  Type: 'type',
  Reason: 'replyReason'
}

export const RoleToKey = {
  [DecisionType.Registrant]: DetailKeys.RegistrantDecision,
  [DecisionType.Admin]: DetailKeys.AdminDecision,
  [DecisionType.Member]: DetailKeys.MemberDecision
}

export const Direction = {
  Outgoing: 2,
  Incoming: 1
}

export const Decision = {
  Undecided: 0,
  Approve: 1,
  Reject: 2
}

export const RejectReason = {
  Nothing: 0,
  Fraud: 1,
  UDRPAction: 2,
  CourtOrder: 3,
  Identity: 4,
  Payment: 5,
  Objection: 6,
  Lock: 7,
  CreationDate: 8,
  TransferDate: 9
}
