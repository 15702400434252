<messages>["../TransferStatus"]</messages>

<template>
  <base-dialog
    v-model="dialog"
    mw1
    scrollable>
    <template #activator="{ on }">
      <v-btn
        icon
        @click="loadDocument"
        v-on="on">
        <v-icon>remove_red_eye</v-icon>
      </v-btn>
    </template>
    <v-card height="100%">
      <v-card-title>
        <div>
          <div class="text-h5">
            {{ $t ('view.label.title', {id: id}) }}
          </div>
          <div
            v-t="'view.label.subtitle'"
            class="cgwng-subheading"/>
        </div>
      </v-card-title>
      <v-card-text height="100%">
        <v-slide-y-transition mode="out-in">
          <v-container
            v-if="isLoading || (!result && !notFound)"
            key="loading"
            class="fill-height">
            <v-row class="fill-height">
              <v-progress-linear
                indeterminate
                color="primary"/>
            </v-row>
          </v-container>
          <template v-else-if="!notFound && result">
            <v-container
              key="order"
              class="fill-height">
              <v-row class="fill-height">
                <v-col
                  class="pa-2"
                  cols="12" lg="6">
                  <v-subheader class="pa-1">
                    {{ $t ('view.label.meta.header') }}:
                  </v-subheader>
                  <name-value-block :items="meta"/>
                </v-col>
                <v-col
                  class="pa-2"
                  cols="12" lg="6">
                  <v-subheader class="pa-1">
                    {{ $t ('view.label.doc') }}:
                    <v-spacer/>
                    <a
                      id="link"
                      :href="result.content"
                      target="_blank"
                      :download="fileName">
                      <v-btn v-t="'view.label.download'"/>
                    </a>
                  </v-subheader>
                  <template
                    v-if="notSupportedType"
                    v-t="'view.error.mimetype'"/>
                  <v-card
                    v-else-if="result.mimetype === 'application/pdf'"
                    class="pa-2"
                    flat
                    color="grey lighten-2">
                    <iframe
                      class="frame"
                      :src="result.content"/>
                  </v-card>
                  <v-card
                    v-else
                    class="pa-2"
                    flat
                    color="grey lighten-2">
                    <pre class="inlineCode frame">
                        {{ text }}
                      </pre>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <v-container key="notFound">
              <v-col cols="12">
                <v-alert type="error">
                  {{ $t ('view.error.notfound', { id: id }) }}
                </v-alert>
              </v-col>
            </v-container>
          </template>
        </v-slide-y-transition>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.back'"
          text
          @click.native="dialog = false"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {mapActions} from 'vuex'
  import {EmptyMark} from '@/app/utils/string'

  import BaseDialog from '@/app/core/components/BaseDialog'
  import NameValueBlock from '@/app/core/components/NameValueBlock'

  export default {
    name: 'DocumentViewDialog',

    components: {
      BaseDialog,
      NameValueBlock
    },

    props: {
      id: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        dialog: false,
        isLoading: false,
        notFound: false,
        result: null,
        text: ''
      }
    },

    computed: {
      meta () {
        return [
          'id',
          'memberId',
          'created',
          'mimetype',
          'comment'
        ].map (i => (
          {
            name: this.$t (`view.label.meta.${i}`),
            value: this.convertedResult
              ? this.convertedResult[i]
              : EmptyMark
          }
        ))
      },

      fileName () {
        return 'Transfer-Document-' + this.id
      },

      convertedResult () {
        if (!this.result) {
          return null
        }
        return {
          ...this.result,
          created: this.result.created
            ? this.formatDate (this.result.created)
            : null
        }
      },

      hasActivatorSlot () {
        return !!this.$scopedSlots.activator
      },

      notSupportedType () {
        if (this.result &&
          (this.result.mimetype === 'text/plain' ||
            this.result.mimetype === 'application/pdf')) {
          return false
        }
        return true
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * load the document from the backend
       */
      loadDocument () {
        if (!this.result && !this.notFound) {
          this.isLoading = true

          const request = {
            op: 'document/load',

            params: {
              id: this.id
            },

            cb: data => {
              this.result = data
              if (this.result.mimetype === 'text/plain') {
                this.readText ().then ((res) => (this.text = res))
              }
            },
            cbError: () => {
              this.notFound = true
            },
            cbFinal: () => {
              this.isLoading = false
            }
          }

          this.fetchData (request)
        }
      },

      /**
       * convert the DataURL to a blob
       * @param dataURI datauri of the file
       * @returns a new blob
       */
      dataURItoBlob (dataURI) {
        let byteString

        if (dataURI.split (',')[0].indexOf ('base64') >= 0) {
          byteString = window.atob (dataURI.split (',')[1])
        } else {
          byteString = unescape (dataURI.split (',')[1])
        }

        const mimeString = dataURI.split (',')[0].split (':')[1].split (';')[0]
        const ia = new Uint8Array (byteString.length)

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt (i)
        }

        return new window.Blob ([ia], {type: mimeString})
      },

      /**
       * Read the text from the blob
       * @returns promise which resolves with the result from reading from the
       *          blob
       */
      readText () {
        return new Promise ((resolve, reject) => {
          const blob = this.dataURItoBlob (this.result.content)
          const reader = new window.FileReader ()

          reader.onload = () => resolve (reader.result)

          reader.readAsText (blob)
        })
      }
    }
  }
</script>

<style scoped>
  .inlineCode {
    white-space: pre-line;
    overflow-y: scroll;
  }
  #link {
    text-decoration: none;
  }
  .frame {
    display: block;
    width: 100%;
    height: 50vh;
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0 none;
    box-sizing: border-box;
  }
</style>
