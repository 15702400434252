<messages>["../TransferStatus"]</messages>

<template>
  <v-row>
    <v-col>
      <a ref="link"/>
      <v-data-table
        :headers="headers"
        :no-data-text="$t ('table.noData')"
        :items="items ()"
        :items-per-page="-1"
        hide-default-footer
        class="elevation-1">
        <template #item="props">
          <tr>
            <td>
              {{ formatDate (props.item.created, 'datetime') }}
            </td>
            <template v-if="!$vuetify.breakpoint.xs">
              <td class="text-right">
                {{ props.item.contentMimeType }}
              </td>
              <td class="text-right">
                {{ props.item.comment }}
              </td>
            </template>

            <td class="text-right nobr">
              <v-row align="end">
                <v-col>
                  <document-view-dialog :id="props.item.id"/>
                </v-col>

                <v-col>
                  <v-btn
                    :loading="props.item.isLoading"
                    icon
                    @click="download (props.item)">
                    <v-icon>arrow_downward</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
  import {mapActions} from 'vuex'
  import DocumentViewDialog from './DocumentViewDialog'

  export default {
    name: 'DocumentTable',

    components: {
      DocumentViewDialog
    },

    props: {
      docs: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        isLoading: false
      }
    },

    computed: {
      headers () {
        return [
          {
            text: this.$t ('table.header.created'),
            align: 'left',
            value: 'creation',
            sortable: false
          },
          ...this.$vuetify.breakpoint.xs
            ? []
            : [
              {
                text: this.$t ('table.header.mimetype'),
                align: 'right',
                value: 'mimetype',
                sortable: false
              },
              {
                text: this.$t ('table.header.comment'),
                align: 'right',
                value: 'comment',
                sortable: false
              }
            ],
          ...[
            {
              text: this.$t ('table.header.action'),
              align: 'right',
              value: 'action',
              sortable: false
            }
          ]
        ]
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * downloads the file of the given item
       * @param item item of the list with an id property
       */
      download (item) {
        item.isLoading = true

        const request = {
          op: 'document/load',

          params: {
            id: item.id
          },

          cb: data => {
            this.$refs.link.setAttribute ('href', data.content)

            this.$refs.link.setAttribute (
              'download', 'Transfer-Document-' + data.id)

            this.$refs.link.click ()
          },
          cbError: () => {
          },
          cbFinal: () => {
            item.isLoading = false
          }
        }

        this.fetchData (request)
      },

      /**
       * Take the docs from the props and a a is loading attribute
       * @returns list of docs with isLoading attribute
       */
      // TODO, FIXME: could it be just computed property?
      items () {
        return this.docs.map ((item) => {
          return {
            ...item,
            isLoading: false
          }
        })
      }
    }
  }
</script>

<style scoped>
  .nobr {
    white-space: nowrap;
  }
</style>
