<messages>["../TransferStatus"]</messages>

<template>
  <v-card
    height="100%"
    width="100%">
    <v-card-title primary-title>
      <div>
        <div
          v-t="'view.label.status.status'"
          class="text-h5"/>
        <div
          v-t="'view.label.statusDescription'"
          class="cgwng-subheading"/>
      </div>
    </v-card-title>
    <v-card-text>
      <v-slide-y-transition mode="out-in">
        <v-container
          v-if="isLoading"
          key="loading">
          <v-row>
            <v-progress-linear
              indeterminate
              color="primary"/>
          </v-row>
        </v-container>
        <template v-else-if="data">
          <v-container key="status">
            <v-row>
              <v-col cols="12">
                <b>{{ $t (`view.label.type.${data.type}`) + ': ' }}</b>
                {{ data.name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <status-table
                  :transfer-data="data"
                  :selected-id="selectedId"
                  @detail="emitDetail"
                  @refresh="emitRefresh"/>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-slide-y-transition>
    </v-card-text>
  </v-card>
</template>

<script>
  import StatusTable from './StatusTable'

  export default {
    name: 'StatusCard',

    components: {
      StatusTable
    },

    props: {
      data: {
        type: Object,
        default: undefined
      },
      isLoading: Boolean,
      selectedId: {
        type: Number,
        required: true
      }
    },

    data () {
      return {}
    },

    methods: {
      /**
       * emits that a detail view should be displayed
       * @param id id of the transfer
       */
      emitDetail (id) {
        this.$emit ('detail', id)
      },

      /**
       * emits that a detail view should be refreshed
       * @param id id of the transfer
       */
      emitRefresh (transfer) {
        this.$emit ('refresh', transfer)
      }
    }
  }
</script>
