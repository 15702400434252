<messages>["../TransferStatus"]</messages>

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="sortedItems"
      :items-per-page.sync="pagination.rowsPerPage"
      :sort-by.sync="pagination.sortBy"
      :sort-desc.sync="pagination.descending"
      hide-default-footer
      class="elevation-1">
      <template #item="props">
        <tr :class="{ highlighted: highlight === props.index, pointer: true }" @click="select (props.item.id, props.index)">
          <!-- <td>
            <a>
              {{ props.item.name }}
            </a>
          </td> -->
          <td v-if="canSeeMemberId && !$vuetify.breakpoint.smAndDown">
            {{ props.item.memberId }}
          </td>
          <td class="text-center">
            {{ formattedDate ( props.item.start, true) }}
          </td>
          <td class="text-center">
            {{ formattedDate (props.item.end, true) }}
          </td>
          <td class="text-right">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  :color="iconDir(props.item.direction).color"
                  v-on="on">
                  {{ iconDir(props.item.direction).icon }}
                </v-icon>
              </template>
              <span v-t="`view.label.direction.${props.item.direction}`"/>
            </v-tooltip>
            <!-- <template
 v-if="$vuetify.breakpoint.smAndUp"
v-t="`view.label.direction.${props.item.direction}`"/> -->
          </td>
          <td class="text-right">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  :color="iconStatus(props.item.status).color"
                  v-on="on">
                  {{ iconStatus(props.item.status).icon }}
                </v-icon>
              </template>
              <span v-t="`view.label.status.${props.item.status}`"/>
            </v-tooltip>
            <!-- <template
 v-if="$vuetify.breakpoint.smAndUp"
v-t="`view.label.status.${props.item.status}`"/> -->
          </td>
          <td class="text-center">
            <v-tooltip
              v-if="hasAvailableDecisions (props.item)"
              left>
              <template #activator="{ on }">
                <v-btn
                  icon
                  color="primary"
                  class="ma-0 pa-0"
                  v-on="on"
                  @click.stop="startDecideTransfer (props.item)">
                  <v-icon>gavel</v-icon>
                </v-btn>
              </template>
              <span v-t="'general.button.decide'"/>
            </v-tooltip>
            <v-tooltip
              v-if="mayCancel (props.item)"
              left>
              <template #activator="{ on }">
                <v-btn
                  icon
                  color="primary"
                  class="ma-0 pa-0"
                  v-on="on"
                  @click.stop="startCancelTransfer (props.item)">
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
              <span v-t="'general.button.cancel'"/>
            </v-tooltip>
            <template v-if="!hasAvailableDecisions (props.item) && !mayCancel (props.item)">
              -
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
    <confirmation-dialog
      v-model="showCancelConfirmation"
      :headline="$t ('view.cancel.headline')"
      @ok="finishCancelTransfer">
      {{
        $t ('view.cancel.text', {
          object: transferToCancel ? transferToCancel.name : null
        })
      }}
    </confirmation-dialog>
    <decision-dialog
      :id="transferToDecide === null ? 0 : transferToDecide.id"
      v-model="showDecisionDialog"
      :options="transferToDecide === null ? [] : transferToDecide.options"
      @decided="finishDecideTransfer"/>
  </div>
</template>

<script>
  import {Decision, DecisionType, Direction, Status, RoleToKey} from './constants'

  import {mapActions, mapGetters, mapMutations} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import DecisionDialog from './DecisionDialog'

  export default {
    name: 'StatusTable',

    components: {
      ConfirmationDialog,
      DecisionDialog
    },

    props: {
      transferData: {type: Object, required: true},
      selectedId: {type: Number, required: true}
    },

    data () {
      return {
        showCancelConfirmation: false,
        showDecisionDialog: false,
        transferToCancel: null,
        transferToDecide: null,
        highlight: null,
        pagination: {
          descending: true,
          sortBy: 'start',
          rowsPerPage: -1
        }
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'hasAnyOfPermissions',
        'mayManageObject'
      ]),

      headers () {
        return [
          // {
          //   text: this.$t ('table.header.name'),
          //   align: 'left',
          //   value: 'date',
          //   sortable: false
          // },
          ...this.canSeeMemberId && !this.$vuetify.breakpoint.smAndDown
            ? [{
              text: this.$t ('table.header.memberId'),
              align: 'left',
              value: 'memberId',
              sortable: true
            }]
            : [],
          {
            text: this.$t ('table.header.started'),
            align: 'center',
            value: 'start',
            sortable: true
          },
          {
            text: this.$t ('table.header.ended'),
            align: 'center',
            value: 'end',
            sortable: false
          },
          {
            text: this.$t ('table.header.direction'),
            align: 'right',
            value: 'direction',
            sortable: true
          },
          {
            text: this.$t ('table.header.status'),
            align: 'right',
            value: 'status',
            sortable: true
          },
          {
            text: this.$t ('table.header.action'),
            value: 'action',
            align: 'center',
            sortable: false
          }
        ]
      },

      canSeeMemberId () {
        return !!this.hasAnyOfPermissions ([
          'ManageAllEntities', 'ManageSubEntities',
          'ViewAllEntities', 'ViewSubEntities'
        ])
      },

      sortedItems () {
        const items = [...this.transferData.states]

        items.sort ((a, b) => {
          return b.start - a.start
        })

        return items
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        setSuccess: 'notification/setSuccess'
      }),

      ...mapMutations ('notification', ['addEvent']),

      mayCancel (item) {
        return item.direction === Direction.Incoming &&
          item.status === Status.Pending
      },

      hasAvailableDecisions (item) {
        return this.getAvailableDecisions (item).length > 0
      },

      getAvailableDecisions (item) {
        let decisions = []

        if (this.hasAnyOfPermissions (['ManageAllObjects'])) {
          decisions =
            decisions.concat ([DecisionType.Admin, DecisionType.Registrant])
        }

        if (item.direction === Direction.Outgoing &&
          this.mayManageObject (item.memberId)) {
          decisions.push (DecisionType.Member)
        }

        return decisions.filter (d => item.status === Status.Pending &&
          item[RoleToKey[d]] === Decision.Undecided)
      },

      startCancelTransfer (item) {
        this.showCancelConfirmation = true
        this.transferToCancel = item
      },

      startDecideTransfer (item) {
        this.showDecisionDialog = true
        this.transferToDecide = {...item, options: this.getAvailableDecisions (item)}
      },

      finishCancelTransfer () {
        this.showCancelConfirmation = false

        this.fetchData ({
          op: 'transfer/cancel',
          params: {
            id: this.transferToCancel.id
          },
          cb: (data) => {
            this.setSuccess (this.$t ('view.cancel.success'))
            this.addEvent ({
              type: 'success',
              message: this.$t ('view.cancel.success'),
              objects: [{name: this.transferData.name, link: {name: 'transferstatus.check', query: {domain: this.transferData.name}}}]
            })
          }
        })
      },

      finishDecideTransfer (transfer) {
        this.addEvent ({
          type: 'success',
          message: this.$t ('view.label.decisionSuccess'),
          objects: [{name: this.transferData.name, link: {name: 'transferstatus.check', query: {domain: this.transferData.name}}}]
        })
        this.$emit ('refresh', transfer)
      },

      /**
       * Icon of the transfer
       * @param name name of the direction/status
       * @return {Object}   an icon object contaning icon type and color
       */
      iconStatus (name) {
        switch (name) {
          case Status.Pending:
            return {
              icon: 'timer',
              color: 'yellow'
            }
          case Status.Rejected:
          case Status.Cancelled:
            return {
              icon: 'cancel',
              color: 'red'
            }
          case Status.Approved:
            return {
              icon: 'check',
              color: 'green'
            }
        }
      },

      /**
       * Icon of the transfer
       * @param name name of the direction
       * @return {Object}   an icon object contaning icon type and color
       */
      iconDir (name) {
        switch (name) {
          case Direction.Incoming:
            return {
              icon: 'arrow_downward',
              color: 'green'
            }
          case Direction.Outgoing:
            return {
              icon: 'arrow_upward',
              color: 'red'
            }
        }
      },

      /**
       * formats the data
       * @param value timestamp
       * @returns string representation of the data or an empty mark
       */
      formattedDate (value) {
        return !value ? '-' : this.formatDate (value)
      },

      /**
       * emits the detail event to the parent
       * @param id        ID of the transfer
       * @param index     table index of the transfer
       */
      select (id, index) {
        this.$emit ('detail', id)
        this.highlight = index
      }
    }
  }
</script>

<style scoped>
  .pointer { cursor: pointer; }

  .highlighted {
    background-color: rgb(238, 238, 238) !important;
  }
</style>
