<messages>["../TransferStatus"]</messages>

<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="12" xl="6">
        <v-row>
          <v-col
            class="d-flex"
            cols="12" sm="6" md="4" lg="6">
            <v-card
              tile
              class="elevation-1 grow">
              <v-card-title>
                <div>
                  <strong v-t="'view.section.basic'"/>
                </div>
              </v-card-title>
              <v-card-text class="pa-6">
                <name-value-block :items="basicData"/>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            class="d-flex"
            cols="12" sm="6" md="4" lg="6">
            <v-card
              tile
              class="elevation-1 grow">
              <v-card-title>
                <div>
                  <strong v-t="'view.section.memberclient'"/>
                </div>
              </v-card-title>
              <v-card-text class="pa-6">
                <name-value-block :items="memberClientData"/>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            class="d-flex"
            cols="12" sm="6" md="4" lg="6">
            <v-card
              tile
              class="elevation-1 grow">
              <v-card-title>
                <div>
                  <strong v-t="'view.section.foa'"/>
                </div>
              </v-card-title>
              <v-card-text class="pa-6">
                <name-value-block :items="foaData"/>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            class="d-flex"
            cols="12" sm="6" md="4" lg="6">
            <v-card
              tile
              class="elevation-1 grow">
              <v-card-title>
                <div>
                  <strong v-t="'view.section.process'"/>
                </div>
              </v-card-title>
              <v-card-text class="pa-6">
                <name-value-block :items="processData"/>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            class="d-flex"
            cols="12" sm="6" md="4" lg="6">
            <v-card
              tile
              class="elevation-1 grow">
              <v-card-title>
                <div>
                  <strong v-t="'view.section.decision'"/>
                </div>
              </v-card-title>
              <v-card-text class="pa-6">
                <name-value-block :items="decisionData"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="justify-center"
        cols="12" xl="6">
        <document-table :docs="data.docs"/>
        <document-upload-dialog
          :transfer-id="id"
          @uploaded="$emit ('refresh', id)"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {Direction, DetailKeys} from './constants'

  import {EmptyMark} from '@/app/utils/string'

  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import RegistryTypeName from '@/app/core/components/RegistryTypeName'

  import DocumentTable from './DocumentTable'
  import DocumentUploadDialog from './DocumentUploadDialog'
  import {mapGetters} from 'vuex'

  export default {
    name: 'TransferDetails',

    components: {
      DocumentTable,
      DocumentUploadDialog,
      NameValueBlock
    },

    props: {
      data: {
        type: Object,
        required: true
      },

      id: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        dateKeys: ['init', 'start', 'end', 'deadline']
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'permissions',
        'mayManageObject'
      ]),

      basicData () {
        const basicKeys = ['name', 'type', 'registry', 'member', 'direction',
                           'status']

        return this.filterInfos (basicKeys)
      },

      memberClientData () {
        const mCKeys = ['losingClient', 'gainingClient', 'losingRegistrar',
                        'gainingRegistrar']

        return this.filterInfos (mCKeys)
      },

      foaData () {
        const FOAKeys = ['regName', 'regEmail', 'adminName', 'adminEmail']
        return this.filterInfos (FOAKeys)
      },

      processData () {
        const processKeys = ['start', 'init', 'deadline', 'end', 'result',
                             'subResults']

        return this.filterInfos (processKeys)
      },

      decisionData: {
        cache: false,
        get () {
          const decisionKeys = ['regDecision', 'adminDecision',
                                'memberDecision', 'replyReason', 'replyComment']

          return this.filterInfos (decisionKeys)
        }
      },

      items: {
        cache: false,
        get () {
          return Object.keys (this.data)
            .map ((key) => {
              return this.createValue (key)
            }).filter ((o) => {
              return o.role !== 'docs' && o.show
            })
        }
      }
    },

    methods: {
      /**
       * get the value to a key for an transfer attribute
       */
      getValue (key) {
        return this.data[key]
          ? this.dateKeys.includes (key)
            ? this.formatDate (this.data[key])
            : !(Array.isArray (this.data[key]) &&
              this.data[key].length === 0)
              ? this.translateProperty (this.data[key], key)
              : EmptyMark
          : EmptyMark
      },

      /**
       * emits the decided event to the parent
       */
      decided () {
        this.$emit ('refresh', this.id)
      },

      /**
       * Filter the items property
       * @param includedKeys keys to include in the filtered result
       */
      filterInfos (includedKeys) {
        return this.items.filter ((item) => {
          return includedKeys.includes (item.role)
        })
      },

      /**
       * Get the slot value for the name value block
       * @param key key for the value
       */
      getSlotValue (key) {
        switch (key) {
          case 'registry':
            return this.$createElement (RegistryTypeName, {
              props: {
                value: this.getValue (key)
              }
            })
          default:
            return null
        }
      },

      /**
       * create the object for the name value block
       * @param key key for the value
       */
      createValue (key) {
        return {
          name: `view.label.${key}.${key}` === this.$t (`view.label.${key}.${key}`) ? this.$t (`view.label.${key}`) : this.$t (`view.label.${key}.${key}`),
          value: this.getValue (key),
          role: key,
          slotValue: this.getSlotValue (key),
          show: key === 'init'
            ? this.data.direction === Direction.Incoming
            : true
        }
      },

      /**
       * Translate the prop for the anme value block
       * @param prop name of the prop
       */
      translateProperty (prop, key) {
        if (
          [
            DetailKeys.AdminDecision,
            DetailKeys.MemberDecision,
            DetailKeys.RegistrantDecision
          ].includes (key)
        ) {
          return this.tryTranslate (`view.label.decided.${prop}`, prop)
        }

        if (key === DetailKeys.Type) {
          return this.tryTranslate (`view.label.type.${prop}`, prop)
        }

        if (key === DetailKeys.Direction) {
          return this.tryTranslate (`view.label.direction.${prop}`, prop)
        }

        if (key === DetailKeys.Status) {
          return this.tryTranslate (`view.label.status.${prop}`, prop)
        }

        return this.tryTranslate (`view.label.prop.${prop}`, prop)
      },

      tryTranslate (path, alt) {
        if (this.$t (path) !== path) {
          return this.$t (path)
        } else {
          return alt || path
        }
      }
    }
  }
</script>
