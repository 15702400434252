<messages>["../TransferStatus"]</messages>

<template>
  <base-dialog
    v-model="dialog"
    mw1
    scrollable>
    <template #activator="{ on }">
      <div v-on="on">
        <v-btn v-t="'docUpload.label.upload'"/>
      </div>
    </template>
    <v-card>
      <v-card-title>
        <div>
          <div
            v-t="'docUpload.title'"
            class="text-h5"/>
          <div
            v-t="'docUpload.subtitle'"
            class="cgwng-subheading"/>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="comment"
                :disabled="isLoading"
                :label="$t ('docUpload.label.logComment')"/>
            </v-col>
            <v-col cols="12" md="6">
              <input
                ref="upload"
                accept="text/plain, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/rtf, application/vnd.oasis.opendocument.text"
                class="hidden"
                type="file"
                @change="fileChanged">
              <v-btn
                v-t="'docUpload.label.browse'"
                @click="browse"/>
              <span
                v-if="error"
                class="red--text">
                {{ errorText }}
              </span>
              <template v-else>
                {{ fileName }}
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.cancel'"
          text
          :disabled="isLoading"
          @click.native="close"/>
        <v-btn
          v-t="'general.button.submit'"
          color="primary"
          :loading="isLoading"
          @click.native="submit"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {mapActions, mapMutations} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    name: 'DocumentUploadDialog',

    components: {
      BaseDialog
    },

    props: {
      transferId: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        dialog: false,
        comment: '',
        file: null,
        isLoading: false,
        error: false,
        errorText: '',
        allowedTypes: ['text/plain', 'application/pdf', 'application/msword',
                       'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                       'application/rtf', 'application/vnd.oasis.opendocument.text']
      }
    },

    computed: {
      fileName () {
        return this.file ? this.file.name : this.$t ('docUpload.error.nothing')
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * submit the document to the backend
       */
      submit () {
        if (this.file && !this.error) {
          this.isLoading = true
          this.getBase64 (this.file).then ((fileString) => {
            console.log (this.file)
            const request = {
              op: 'document/transferupload',

              params: {
                transferId: this.transferId,
                file: fileString,
                comment: this.comment,
                type: this.file.type !== '' ? this.file.type : 'text/plain'
              },

              cb: () => {
                this.$emit ('uploaded')
                this.displaySuccessMessage (this.$t ('docUpload.success'))
                this.close ()
              },
              cbError: () => {
                this.notFound = true
              },
              cbFinal: () => {
                this.isLoading = false
              }
            }

            this.fetchData (request)
          }).catch ((err) => {
            console.error (err)
          })
        }
      },

      /**
       * open the system file browser
       */
      browse () {
        this.$refs.upload.click ()
      },

      /**
       * check file size and mime type
       *
       */
      fileChanged (e) {
        if (!e.target.files[0]) {
          this.file = null
          this.error = false
          return
        }
        if (e.target.files[0].size < 1e+7) {
          if (this.allowedTypes.includes (e.target.files[0].type)) {
            this.error = false
            this.errorText = ''
            this.file = e.target.files[0]
          } else {
            this.error = true
            this.errorText = this.$t ('docUpload.error.invalidType')
            this.file = null
          }
        } else {
          this.errorText = this.$t ('docUpload.error.tooBig')
          this.error = true
          this.file = null
        }
      },

      /**
       * get the dataurl from a file
       * @param file file for which a dataurl should be created
       * @returns a promise which resiles with the result
       */
      getBase64 (file) {
        return new Promise ((resolve, reject) => {
          const reader = new window.FileReader ()
          reader.onload = () => resolve (reader.result)
          reader.onerror = error => reject (error)
          reader.readAsDataURL (file)
        })
      },

      /**
       * closes the dialog and resets the data
       */
      close () {
        this.isLoading = false
        this.file = null
        this.error = false
        this.errorText = ''
        this.dialog = false
      }
    }
  }
</script>
<style scoped>
  .hidden {
    width: 1px;
    visibility: hidden;
  }
</style>
