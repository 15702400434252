<messages>["./TransferStatus"]</messages>

<template>
  <base-layout :mw="$vuetify.breakpoint.lgAndDown ? '2' : '3'">
    <v-col cols="12" md="12" lg="4" xl="4">
      <submit-card
        :name-value="name"
        :is-loading="isLoadingData"
        @submit="submit"
        @reset="reset"/>
    </v-col>
    <v-col cols="12" md="12" lg="8" xl="8">
      <v-slide-y-transition mode="out-in">
        <status-card
          v-if="showData"
          :data="data"
          :selected-id="selectedId"
          :is-loading="isLoadingData"
          @detail="loadDetails"
          @refresh="refreshDetails"
          @decided="decided"/>
      </v-slide-y-transition>
    </v-col>
    <v-col
      id="details"
      cols="12">
      <v-slide-y-transition mode="out-in">
        <detailed-status
          v-if="showDetails"
          :id="id"
          :is-loading="isLoadingDetails"
          :data="details"
          @refresh="refreshDetails"/>
      </v-slide-y-transition>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapActions} from 'vuex'
  import goTo from 'vuetify/lib/services/goto'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import SubmitCard from './components/SubmitCard'
  import StatusCard from './components/StatusCard'
  import DetailedStatus from './components/DetailedStatus'

  import {ObjectType, TypeToName} from './components/constants'

  export default {
    name: 'TransferStatus',

    components: {
      SubmitCard,
      StatusCard,
      DetailedStatus,
      BaseLayout
    },

    data () {
      return {
        name: '',
        data: null,
        isLoadingData: false,
        showData: false,
        details: null,
        showDetails: false,
        isLoadingDetails: false,
        selectedId: 0,
        id: 0,
        currentSubmitEvent: null
      }
    },

    created () {
      if (this.$route.query[TypeToName[ObjectType.domain]] || this.$route.query[TypeToName[ObjectType.email]]) {
        this.name = this.$route.query[TypeToName[ObjectType.domain]] || this.$route.query[TypeToName[ObjectType.email]]

        this.submit ({
          type: this.$route.query[TypeToName[ObjectType.domain]] ? ObjectType.domain : ObjectType.email,
          name: this.name
        })

        if (this.$route.query.id) {
          const id = Number.parseInt (this.$route.query.id)
          if (!Number.isNaN (id)) {
            this.loadDetails (id)
          } else {
            this.$router.replace ({query: {...this.$route.query, id: -1}})
          }
        }
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * Submit the request for checking a transfer for an email / a domain
       * @param e event with type and name property
       */
      submit (e, resubmit = false) {
        this.currentSubmitEvent = e
        this.name = e.name
        this.selectedId = 0
        this.$router.replace ({query: {...this.$route.query, [TypeToName[e.type]]: e.name}})
        this.isLoadingData = true
        this.showData = true
        this.data = null

        if (!resubmit) {
          this.showDetails = false
        }

        this.fetchData ({
          op: 'transfer/check',
          params: {
            type: e.type,
            name: e.name
          },
          cb: data => {
            this.data = data
            if (!data?.states?.some (s => s.id === this.id)) {
              this.$router.replace ({query: {...this.$route.query, id: null}})
              this.showDetails = false
            }
          },
          cbFinal: () => {
            if (!this.data) {
              this.showData = false
            }
            this.isLoadingData = false
          },
          cbError: () => {
            this.showDetails = false
          }
        })
      },

      /**
       * refresh the details view, i.e. reload the details in case they are
       * already displayed, do nothing otherwise
       *
       * @param tranfer     with the ID of the transfer for which the data shall
       *                    be refreshed
       */
      refreshDetails (id) {
        this.resubmit ()
        if (this.showDetails) {
          this.loadDetails (id)
        }
      },

      resubmit () {
        this.submit (this.currentSubmitEvent, true)
      },

      /**
       * loading a details for a transfer
       * @param id id of the transfer the details should be loaded for
       */
      loadDetails (id) {
        this.selectedId = id
        this.isLoadingDetails = true
        this.$router.replace ({query: {...this.$route.query, id}})
        this.showDetails = true
        this.details = null
        const request = {
          op: 'transfer/details',
          params: {
            id: id
          },
          cb: data => {
            this.details = data.transfer
            this.id = data.id
          },
          cbFinal: () => {
            if (!this.details) {
              this.showDetails = false
              this.selectedId = 0
            }
            this.isLoadingDetails = false

            goTo ('#details')
          }
        }

        this.fetchData (request)
      },

      /**
       * reset the whole transfer check page
       */
      reset () {
        this.$router.replace ({query: { }})
        this.isLoadingData = false
        this.data = {}
        this.showData = false
        this.showDetails = false
      },

      /**
       * Sets the details the transmitted fata
       * @param data new detailed data
       */
      decided (data) {
        this.details = null
        this.showDetails = false
        this.details = data
        this.showDetails = true
      }
    }
  }
</script>
