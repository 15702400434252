<messages>["../TransferStatus"]</messages>

<template>
  <v-row
    align="center"
    class="ma-0">
    <base-dialog
      v-model="dialog"
      mw0
      persistent>
      <v-card>
        <v-card-title>
          <span
            v-t="'view.label.transferDecision'"
            class="text-h5"/>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedRole"
                  solo
                  :disabled="roleOptions.length < 2"
                  :items="roleOptions"
                  :label="this.$t ('view.label.roleSelect')"/>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-if="selectedRole !== null"
                  v-model="selectedDecision"
                  solo
                  :items="decisionOptions"
                  :label="this.$t ('view.label.decisionSelect')"/>
              </v-col>
              <v-slide-y-transition mode="out-in">
                <v-col
                  v-if="selectedDecision !== decision.Undecided &&
                    selectedRole === decisionType.Member"
                  key="transfercomment"
                  cols="12">
                  <v-text-field
                    v-model.trim="transferComment"
                    :label="this.$t ('view.label.transferComment')"/>
                </v-col>
              </v-slide-y-transition>
              <v-slide-y-transition mode="out-in">
                <v-col
                  v-if="selectedDecision === decision.Reject &&
                    selectedRole === decisionType.Member"
                  key="transferreason"
                  cols="12">
                  <v-autocomplete
                    v-model="reason"
                    :items="reasonOptions"
                    :error-messages="reasonError ? $t ('view.label.reasonSelect') : []"
                    :label="this.$t ('view.label.reasonSelect')"/>
                </v-col>
              </v-slide-y-transition>
              <v-slide-y-transition mode="out-in">
                <v-col
                  v-if="selectedDecision === decision.Reject"
                  key="logcomment"
                  cols="12">
                  <v-text-field
                    v-model.trim="logComment"
                    :label="this.$t ('view.label.logComment')"/>
                </v-col>
              </v-slide-y-transition>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.cancel'"
            text
            :disabled="isLoading"
            @click.native="cancel"/>
          <v-btn
            v-t="'general.button.submit'"
            color="primary"
            :loading="isLoading"
            @click.native="submit"/>
        </v-card-actions>
      </v-card>
    </base-dialog>
  </v-row>
</template>

<script>
  import {DecisionType, Decision, RejectReason} from './constants'

  import {mapActions, mapMutations} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    name: 'DecisionDialog',

    components: {
      BaseDialog
    },

    props: {
      value: {
        type: Boolean,
        required: true
      },
      id: {
        type: Number,
        required: true
      },
      options: {
        type: Array,
        default: () => ([DecisionType.Admin, DecisionType.Member, DecisionType.Registrant])
      }
    },

    data () {
      return {
        // assignments needed to use external constants in template
        decision: Decision,
        role: null,
        decisionType: DecisionType,
        selectedDecision: Decision.Undecided,
        submitted: false,
        reason: RejectReason.Nothing,
        transferComment: '',
        logComment: '',
        decisionOptions: [
          Decision.Undecided,
          Decision.Approve,
          Decision.Reject
        ].map (
          (i) => ({text: this.$t (`view.label.decision.${i}`), value: i})
        ),
        reasonOptions: [
          RejectReason.Nothing,
          RejectReason.Fraud,
          RejectReason.UDRPAction,
          RejectReason.CourtOrder,
          RejectReason.Identity,
          RejectReason.Payment,
          RejectReason.Objection,
          RejectReason.Lock,
          RejectReason.CreationDate,
          RejectReason.TransferDate
        ].map (
          (i) => ({text: this.$t (`view.label.reason.${i}`), value: i})
        ),
        isLoading: false
      }
    },

    computed: {
      reasonError () {
        return (this.selectedRole === DecisionType.Member &&
          this.selectedDecision === Decision.Reject &&
          this.reason === RejectReason.Nothing &&
          this.submitted)
      },

      dialog: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      },

      roleOptions () {
        return [DecisionType.Registrant, DecisionType.Admin, DecisionType.Member]
          .map ((i) => ({text: this.$t (`view.label.role.${i}`), value: i}))
          .filter ((i) => {
            return this.options.includes (i.value)
          })
      },

      selectedRole: {
        get () {
          return this.options.length === 1 ? this.options[0] : this.role
        },
        set (val) {
          this.role = val
        }
      }
    },

    watch: {
      value (val) {
        if (!val) {
          this.reset ()
        }
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        setError: 'notification/setError',
        setSuccess: 'notification/setSuccess'
      }),

      reset () {
        this.submitted = false
        this.selectedRole = null
        this.selectedDecision = Decision.Undecided
        this.reason = RejectReason.Nothing
        this.transferComment = ''
        this.logComment = ''
      },

      /**
       * closes the dialog and resets the data
       */
      cancel () {
        this.reset ()
        this.dialog = false
      },

      /**
       * submits the decision to the backend
       */
      submit () {
        this.submitted = true
        if (this.selectedDecision === Decision.Undecided) {
          this.cancel ()
          return
        }
        if (!this.reasonError) {
          this.isLoading = true

          const request = {
            op: 'transfer/changedecision',
            params: {
              id: this.id,
              role: this.selectedRole === null ? 0 : this.selectedRole,
              decision: this.selectedDecision,
              transferComment: this.transferComment,
              logComment: this.logComment,
              reason: this.reason
            },
            cb: (data) => {
              this.$emit ('decided', {id: this.id, role: this.selectedRole, decision: this.selectedDecision})
              this.dialog = false
              this.setSuccess (this.$t ('view.label.decisionSuccess'))
            },
            cbError: (data, next) => {
              if (data.result.find ((r) => r.code === 'error/datamgmt/objassoc')) {
                this.setError (this.$t ('view.error.alreadyDecided'))
              } else {
                next (data)
              }
            },
            cbFinal: () => {
              this.isLoading = false
            }
          }

          this.fetchData (request)
        }
      }
    }
  }
</script>

<style scoped>
  .buttonContainer {
    flex-grow: 0;
  }
</style>
